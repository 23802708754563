import { fetchAuthSession } from 'aws-amplify/auth';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { IMAGES } from '../../../../../constants/ImageConstants';
import { ROUTE } from '../../../../../constants/Routes';
import { useViewport } from '../../../../../hooks';
import { handleUNO } from '../../../../../redux/Reducer/Auth';
import {
  getPendingNotifications,
  getUsersData,
} from '../../../../../redux/Reducer/Profile';
import { toggleSideNav } from '../../../../../redux/Reducer/Utils';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '../../../../Containers/index';
import LogoutModal from '../../../Auth/Components/Logout';
import { HeaderAppBar } from '../Settings';
function NavBar() {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { isSideNavOpen } = useSelector((state) => state.utils);
  const { userData } = useSelector((state) => state?.user);
  const checkLanguage = localStorage.getItem('selectedLanguage');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [isInternal, setIsInternal] = useState(false);

  const toggleLogoutModal = (value) => {
    setLogoutOpen(value);
    // setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getUsersData());
    dispatch(getPendingNotifications());
    // dispatch(getAnalytics());
    // dispatch(getVocherUsersData());
    // dispatch(getGOFLData());
    // dispatch(getFFLData());
    // dispatch(getGFLData());
    // dispatch(getGIFEData());
    checkLanguage ?? localStorage.setItem('selectedLanguage', 'en');
    // eslint-disable-next-line
  }, []);
  const [showSidebar, setShowSideBar] = useState(true);
  useEffect(() => {
    if (
      userData?.platformDetail?.company_info?.corporate_name === '' &&
      userData?.platformDetail?.company_info?.address?.address_line_one ===
        '' &&
      !userData?.roles?.some((v) => v.includes('USER')) &&
      !userData?.roles?.some((v) => v.includes('SUPER')) &&
      !userData?.roles?.includes('PARTNER_ADMIN')
    ) {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  }, [userData]);
  useEffect(() => {
    if (
      userData?.roles?.includes('PARTNER_SUPER_ADMIN') ||
      userData?.roles?.includes('PARTNER_ADMIN')
    ) {
      if (currentUrl === ROUTE.ONBOARD) {
        navigate('/');
      }
      setIsInternal(true);
    }
    if (userData?.roles?.some((v) => v.includes('UNO'))) {
      dispatch(handleUNO(true));
      if (
        currentUrl === ROUTE.FINANCING_REQUEST ||
        currentUrl === ROUTE.FINANCING_ANALYTICS ||
        currentUrl === ROUTE.DATA_VALIDATION ||
        currentUrl === ROUTE.IMPACT_METRICS
      ) {
        navigate('/');
      }
    }
  }, [userData]);

  const refreshToken = async () => {
    try {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });

      if (!tokens) {
        localStorage.clear();
        window.location.reload();
        navigate('/');
      } else {
        const { idToken, accessToken } = tokens;
        localStorage.setItem('idToken', idToken?.toString());
        localStorage.setItem('access_token', accessToken?.toString());
      }
    } catch (error) {
      console.log('error nav', error);
    }
  };
  const getToken = async () => {
    // TODO to add refresh token logout flow
    let token = localStorage.getItem('idToken');

    if (token) {
      let decoded = jwtDecode(token);

      let expiryTime = new Date(decoded.exp * 1000);

      let currentTime = new Date();
      let MS_PER_MINUTE = 60000;

      const expiryEpochMinus = expiryTime.getTime() - 5 * MS_PER_MINUTE;

      if (expiryEpochMinus <= currentTime.getTime()) {
        await refreshToken();
        dispatch(getUsersData());
        // return localStorage.getItem('idToken');
      }
    } else {
      return null;
    }
  };
  useEffect(() => {
    // currentSession();
    getToken();
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }} className='navbar-component' id='navbar'>
      <HeaderAppBar
        open={isSideNavOpen}
        className='HeaderAppBar'
        // position='absolute'
        sx={{
          bgcolor: '#ffff',
          justifyContent: 'center',
          boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
        }}
      >
        <Toolbar>
          {showSidebar && !isMobile && (
            <IconButton
              sx={{
                mr: 4,
                ...(isSideNavOpen && { display: 'none' }),
              }}
              // color="inherit"
              aria-label='open drawer'
              edge='start'
              onClick={() => {
                dispatch(toggleSideNav(!isSideNavOpen));
              }}
              className='mr-16'
            >
              <ICONS.MENU />
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: 1,
              pl: 1,
            }}
          >
            <img
              src={IMAGES.LOGO}
              height='48'
              width='48'
              style={{ aspectRatio: '3.6428/1' }}
              // onClick={() => {
              //   window.location.reload();
              // }}
              alt='logo'
            />
          </Box>

          <Box className='ml-auto'>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                onClick={handleClick}
                size='small'
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ bgcolor: 'primary.main' }} alt='NA'>
                  {(userData.first_name &&
                    userData?.first_name[0]?.toUpperCase()) ||
                    'NA'}
                </Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {!isInternal && (
                  <MenuItem onClick={() => navigate(ROUTE.ONBOARD)}>
                    {/* <ListItemIcon>
                      <Logout fontSize='small' />
                    </ListItemIcon> */}
                    Company Profile
                  </MenuItem>
                )}
                <MenuItem onClick={() => toggleLogoutModal(true)}>
                  {/* <ListItemIcon>
                    <Logout fontSize='small' />
                  </ListItemIcon> */}
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </HeaderAppBar>
      <LogoutModal open={logoutOpen} handleClose={toggleLogoutModal} />
    </Box>
  );
}

export default NavBar;
